<template>
  <div>
    <!--begin::Body-->
    <div class="kt-login__body" style="margin-top: 30px;">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>{{ $t("AUTH.LOGIN.TITLE") }}</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <div role="alert" class="alert alert-info">
            <div class="alert-text">
              جهت استفاده از حساب تان وارد سیستم شوید.
            </div>
          </div>
          <div v-if="message !== null" role="alert" class="alert alert-dark">
            <div class="alert-text">
              {{ message }}
            </div>
          </div>

          <div
            role="alert"
            v-bind:class="{ show: errors.length }"
            class="alert fade alert-danger"
          >
            <div class="alert-text">
              <ul v-for="(error, i) in errors" :key="i">
                <li>{{ error }}</li>
              </ul>
            </div>
          </div>

          <b-form-group
            id="login-input-group-1"
            label=""
            label-for="login-input-1"
          >
            <b-form-input
              id="login-input-1"
              placeholder="آیدی نمبر شما"
              name="login-input-1"
              v-model="$v.form.id.$model"
              :state="validateState('id')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              درج آیدی نمبر حتمی میباشد.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="login-input-group-2"
            label=""
            label-for="login-input-2"
          >
            <b-form-input
              :type="passwordType"
              placeholder="رمز حساب شما"
              id="login-input-2"
              name="login-input-2"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
            >
            </b-form-input>
            <b-form-invalid-feedback id="input-2-live-feedback">
              درج رمز حساب حتمی میباشد.
            </b-form-invalid-feedback>
            <span class="input-group-append mt-2" id="input-2-live-feedback">
              <span class="input-group-text" @click="setPasswordFieldType()">
                <i
                  class="fa p-2"
                  :class="[
                    passwordType === 'password' ? 'fa-eye-slash' : 'fa-eye'
                  ]"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="mt-3 ml-3">
                {{
                  passwordType === "password"
                    ? "نمایش رمز حساب"
                    : "مخفی کردن رمز حساب"
                }}
              </span>
            </span>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <router-link
              to="/reset-password"
              class="kt-link kt-login__link-forgot"
            >
              {{ $t("AUTH.FORGOT.TITLE") }}
            </router-link>
            <b-button
              type="submit"
              id="kt_submit"
              style="color: white;"
              class="btn btn-primary btn-elevate kt-login__btn-primary"
            >
              {{ $t("AUTH.LOGIN.BUTTON") }}
            </b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, PHOTO } from "@/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      form: {
        id: null,
        password: null
      },
      message: null,
      passwordType: "password"
    };
  },
  validations: {
    form: {
      id: {
        required
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        id: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const id = this.$v.form.id.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );
      submitButton.disabled = true;

      this.$store.dispatch(LOGIN, { id, password }).then(() => {
        this.$router.push({ name: "dashboard" });
        this.$store.dispatch(PHOTO);
      });
    },
    setPasswordFieldType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    }
  },
  watch: {
    errors(newValue) {
      if (
        !(Object.keys(newValue).length === 0 && newValue.constructor === Object)
      ) {
        const submitButton = document.getElementById("kt_submit");
        submitButton.classList.remove(
          "kt-spinner",
          "kt-spinner--light",
          "kt-spinner--right"
        );
        submitButton.disabled = false;
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    }
  },
  created() {
    if (
      this.$route.params.message !== null &&
      this.$route.params.message !== undefined
    ) {
      this.message = this.$route.params.message;
    }
  }
};
</script>
